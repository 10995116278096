import Vue from 'vue'
import VueRouter from 'vue-router'
 
Vue.use(VueRouter)
 
const routes = [
  {
    path: '/',
    name: 'Home',
    component: ()=>import('../components/Home.vue')
  },
  {
    path: '/commodityDetails',
    name: 'commodityDetails',
    component: ()=>import('../components/commodityDetails.vue')
  },
  {
    path: '/orderForm',
    name: 'orderForm',
    component: ()=>import('../components/orderForm.vue')
  }
]
 
const router = new VueRouter({
    mode: 'hash',
    // base: "./",
    routes
})
 
export default router