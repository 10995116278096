import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Swipe, SwipeItem,Lazyload,DatetimePicker,Picker,Loading} from 'vant';


Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Loading);

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
